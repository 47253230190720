import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Button from "../../components/Button/Button";
import DetailPageHeader from "../../components/DetailPageHeader/DetailPageHeader";
import Masonry from "../../components/Masonry/Masonry";
import QuickFacts from "../../components/QuickFacts/QuickFacts";
import QuickFactsItem from "../../components/QuickFactsItem/QuickFactsItem";
import Section from "../../components/Section/Section";
import Sharing from "../../components/Sharing/Sharing";
import {
  useAddToUserCollectionMutation,
  useFetchUserCollectionQuery,
  useRemoveFromUserCollectionMutation,
} from "../../features/api/netlifyApiSlice";
import useMediaQuery from "../../hooks/useMediaQuery";
import { AddIcon24, DeleteIcon24 } from "../../icons";
import ArtworkCard from "../Artwork/ArtworkCard";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ArtworkPage = ({ data: { page, furtherArtworks } }) => {
  const {
    isAuthenticated,
    user,
  } = useSelector((state) => state.identitySlice);

  const { t } = useTranslation();

  const userCollections = useFetchUserCollectionQuery(user.id, {
    skip: typeof user.id === "undefined",
  });

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const [addToUserCollection] = useAddToUserCollectionMutation();
  const [removeFromUserCollection] = useRemoveFromUserCollectionMutation();

  const longInAndAdd = () => {
    netlifyIdentity?.open();
    netlifyIdentity.on("close", () => {
      window.location.reload();
    });
  };

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <DetailPageHeader
        headline={page.title.trim() + (page.year ? `, ${page.year}` : "")}
        image={page.images && page.images.length > 0 && page.images[0]?.asset && (
          <SanityImage
            {...page.images[0]}
            width={800}
            className="w-full"
          />
        )}
        sharing={(
          <Sharing
            shareTitle={page.title}
            title={`${t("shareArtwork")}:`}
          />
        )}
        type={page.artist?.name}
      >
        {!isAuthenticated && (
          <Button
            className="mx-10"
            title={t("loginAndAdd")}
            onClick={longInAndAdd}
          >
            <AddIcon24 />{t("loginAndAdd")}
          </Button>
        )}

        {isAuthenticated
          && (userCollections.isSuccess && (
            userCollections.data.filter(({ _id }) => _id === page._id).length > 0 ? (
              <Button
                title={t("removeFromMyCollection")}
                color="black"
                size="large"
                variant="primary"
                className="uppercase"
                onClick={() => removeFromUserCollection({ artwork: page, userId: user.id })}
              >
                <DeleteIcon24 /> {t("removeFromMyCollection")}
              </Button>
            ) : (
              <Button
                title={t("toMyCollection")}
                color="black"
                size="large"
                variant="primary"
                className="uppercase"
                onClick={() => addToUserCollection({ artwork: page, userId: user.id })}
              >
                <AddIcon24 /> {t("toMyCollection")}
              </Button>
            ))
          )}
      </DetailPageHeader>
      <div className="grid grid-cols-12 mx-18">
        <div className="col-span-12 order-2 lg:order-1 lg:col-span-9">
          {page.description_public
            && (
              <div className="grid grid-cols-12 lg:grid-cols-9">
                <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                  {t("aboutTheArtwork")}
                </div>
                <div className="col-span-12 lg:col-span-9 text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
                  {page.description_public}
                </div>
              </div>
            )}
          {page.media && (
            <div className="grid grid-cols-12 lg:grid-cols-9">
              <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase mt-72 lg:mt-90">
                Medien
              </div>
              <div className="col-span-12 lg:col-span-9 text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
                MEDIA ARRAY HERE
              </div>
            </div>
          )}
          <Section>
            <div className="grid grid-cols-12 lg:grid-cols-9">
              <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase mt-72 lg:mt-90">
                {t("furtherArtworksOfArtist")}
              </div>
              <div className="col-span-12 lg:col-span-8 text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
                <Masonry breakpointCols={isMobile ? 2 : 3}>
                  {furtherArtworks.nodes?.sort(() => 0.5 - Math.random()).slice(0, 12).map(
                    (artwork, index) => (
                      <ArtworkCard data={artwork} className={classNames({ "mt-60": index === 0 || index === 2 })} />
                    ),
                  )}
                </Masonry>
              </div>
            </div>
          </Section>
        </div>
        <div className="col-span-12 order-1 lg:order-2 lg:col-span-3">
          <QuickFacts>
            <QuickFactsItem title={t("artist")}>
              {page.artist?.name}
            </QuickFactsItem>
            <QuickFactsItem title={t("year")}>
              {page.year}
            </QuickFactsItem>
            <QuickFactsItem className="flex" title={t("techniqueAndMaterial")}>
              {page.technique.map((entry) => <div className="flex-auto text-body2" key={entry.value}>{t(entry.value)}</div>)}
              {page.material.map((entry) => <div className="flex-auto text-body2" key={entry.value}>{t(entry.value)}</div>)}
            </QuickFactsItem>
            <QuickFactsItem title={t("dimensions")}>
              {page.dimensions?.width && page.dimensions?.height && (
                // eslint-disable-next-line no-unsafe-optional-chaining
                <div className="text-body2">{page.dimensions?.height / 10}x{page.dimensions?.width / 10}{page.dimensions?.depth ? `x${page.dimensions?.depth / 10}` : ""} cm</div>
              )}
            </QuickFactsItem>
            {page?.rightsHolder?.name && (
              <QuickFactsItem title={t("copyright_quickinfo")}>
                {page?.rightsHolder?.name}
              </QuickFactsItem>
            )}
          </QuickFacts>
        </div>
      </div>
    </MainLayout>
  );
};
// -f891f5df-8c2d-553f-8c9d-9b97107baaec
export const query = graphql`
  query ($id: String!, $artistId: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityArtwork(id: {eq: $id}) {
      id
      _id
      title
      location
      kind
      artist {
        name
        id
      }
      rightsHolder {
        name
        id
      }
      technique {
        value
      }
      material {
        value
      }
      status {
        status
      }
      Metadata {
        ...MetaData
      }
      description_public,
      year,
      dimensions {
        depth
        diameter
        height
        weight
        width
      }
      images {
        ...ImageWithPreview
        alt
      }
    }
    furtherArtworks: allSanityArtwork(
      filter: {artist: {id: {eq: $artistId}}}
    ) {
      nodes {
        ...ArtworkCard
        id
        kind
        slug {
          current
        }
        artist {
          name
        }
        title
        year
        images {
          ...ImageWithPreview
          alt
        }
      }
    }
  }
`;

ArtworkPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ArtworkPage;
